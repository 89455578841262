<template>
  <div class="host-settlement-dashboard">
    <div class="dashboard__section settlement-info">
      <!--자동정산 상점 o-->
      <div v-if="autoHostSettlement()">자동정산 상점입니다.<br />하단 정산내역을 확인해주세요.</div>

      <!--자동정산 상점 x-->
      <div v-else>
        <!--정산상태: 정산가능금액이 있는지-->
        <div v-if="settleAvailable()" class="settlement-info__title">정산 가능한 금액이 있습니다 💰</div>
        <div v-else class="settlement-info__title">정산 가능한 금액이 없습니다 😥</div>
      </div>

      <!-- <ul class="settlement-info__list">
        <li class="settlement-info__list-item">
          <div class="key key--primary">정산 가능 금액</div>
          <div class="value value--primary">{{ totalProfit }}</div>
        </li>
        <li class="settlement-info__list-item">
          <div class="key">이번달 매출액</div>
          <div class="value">{{ monthProfit }}</div>
        </li>
        <li class="settlement-info__list-item">
          <div class="key">누적 정산금</div>
          <div class="value">{{ sumSettled }}</div>
        </li>
      </ul> -->
    </div>

    <div class="dashboard__section actions">
      <!-- <template v-if="!storeId">
        <p class="text">상점을 선택해주세요.</p>
      </template>
      <template v-else-if="settleProgressing">
        <p class="text">입금 요청이 완료되었습니다. 😀</p>
      </template> -->
      <!-- <template v-else-if="primaryAccount">
        <div class="account-area" @click="editAccountInfo">
          <i class="fas fa-cog"></i>
          <div class="account-info">
            <span class="text text--underline">{{ primaryAccount.bank_name }} {{ primaryAccount.bank_account }}</span>
            &nbsp;<span class="text">으로 입금해주세요</span>
          </div>
        </div>
      </template> -->

      <!-- <div class="button-area" v-if="storeId && !settleProgressing"> -->
      <div v-if="!autoHostSettlement()" class="button-area">
        <!--테스트용-->
        <!-- <div class="button-area"> -->
        <!-- <button v-if="!primaryAccount" type="button" class="btn btn--text" @click="editAccountInfo">
          <span class="btn__text"> 정산 받으실 계좌를 등록해주세요 </span>
        </button> -->

        <!--정산 신청 버튼-->
        <!-- <button
          type="button"
          @click="onClickRequestSquareUp"
          class="btn btn--fill"
          :class="{
            activated: squareUpAvailable,
            disabled: !squareUpAvailable
          }"
          :disabled="!squareUpAvailable"
        >
          정산 금액 확인하기
        </button> -->
        <button
          type="button"
          class="btn btn--fill"
          :class="{
            activated: settleAvailable(),
            disabled: !settleAvailable()
          }"
          :disabled="!settleAvailable()"
          @click="onClickRequestSquareUp"
        >
          정산 금액 확인하기
        </button>
      </div>
    </div>

    <ui-slider id="host-edit-bank-account-slider" ref="host-edit-bank-account-slider">
      <HostBankAccountList @close="onCloseEditBankAccount" />
    </ui-slider>
    <div class="dashboard__section tax-info">
      <div class="tax-info_title">세금계산서 발행정보</div>
      <p class="tax-info_content">
        • 럭스테이 사업자번호 : 689-87-01050 <br />
        • 세금계산서 항목 : 물품보관 수수료 <br />
        • 이메일 : support@lugstay.com<br />
      </p>
    </div>
  </div>
</template>

<script>
import { openRef, closeRef } from '@/helpers';
import HostBankAccountList from '@/components/resources/host/HostBankAccountList';
import UiSlider from '@/components/modules/Slider/Body.vue';

const SETTLED_METHODS = {
  WITHHOLDING_TAX: '원천징수',
  TAX_BILL: '세금 계산서'
};

const BANK_COUNTRY = {
  KOREA: 'KR'
};

export default {
  name: 'HostSettlementDashboard',
  props: {
    storeId: {
      type: Number | String
    }
  },
  data() {
    return {
      settledMethod: 'WITHHOLDING_TAX',
      data: {},
      loading: false
    };
  },

  watch: {
    storeId: {
      handler(newValue) {
        this.fetchDashboard();
      }
    }
  },
  mounted() {
    // console.log('props', this.$props.storeId); // 아무것도 없음
  },
  computed: {
    // primaryAccount() {
    //   return this.accountList.find((o = {}) => !!o.is_primary);
    // },

    // squareUpAvailable() {
    //   return this.data.total_profit > 0;
    // },

    accountList() {
      return this.$store.getters['bankAccounts/GET_ITEMS_BY_LIST']({
        list: 'MyBankAccounts'
      });
    },

    // totalProfit() {
    //   return (this.data.total_profit || 0).toLocaleString() + ' ' + this.$__t('won');
    // },

    // monthProfit() {
    //   return (this.data.month_profit || 0).toLocaleString() + ' ' + this.$__t('won');
    // },

    // sumSettled() {
    //   return (this.data.sum_settled || 0).toLocaleString() + ' ' + this.$__t('won');
    // },

    // settleProgressing() {
    //   return this.$store.getters['hostSettlements/GET_ITEMS_BY_LIST']({
    //     list: `MyStoreSettlements_${this.storeId}`
    //   }).some((o) => o.status === 'PROGRESS');
    // },

    //@MEMO: 과거 정산내역 store에서 가져오는 것
    docs() {
      return this.$store.getters['hostSettlements/GET_ITEMS_BY_LIST']({
        list: 'MyStoreSettlements_all'
      });
    }
  },

  created() {
    Promise.all([this.fetchDashboard(), this.fetchBankAccount()]);
  },

  methods: {
    //@MEMO: 자동정산상점인지. 과거정산내역중, 가장 최신리스트에 정산방식이 '원천징수(WITHHODING_TAX)'인 경우 o.
    autoHostSettlement() {
      if (this.docs[0]?.settled_method === 'WITHHOLDING_TAX') {
        return true;
      }
      return false;
    },
    settleAvailable() {
      console.log( "this.$store.getters.config.mode", this.$store.state.config.mode );
      if (this.$store.state.config.mode !== 'production') {
        return true;
      }

      for (let i = 0; i < this.docs.length; i++) {
        if (this.docs[i].status === 'NEW') {
          return true;
        }
      }
      return false;
    },
    //here
    async fetchDashboard() {
      try {
        this.$store.commit('loading/SET_TRUE');
        this.data = await this.$store.dispatch('hostSettlements/dashboard', {
          settled_method: this.settledMethod,
          store_id: this.storeId
        });
      } catch (e) {
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    async fetchBankAccount() {
      try {
        this.$store.commit('loading/SET_TRUE');
        await this.$store.dispatch('bankAccounts/fetchList', {
          page: 1,
          size: 100,
          list: 'MyBankAccounts'
        });
      } catch (e) {
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    editAccountInfo() {
      openRef.call(this, 'host-edit-bank-account-slider');
    },

    onCloseEditBankAccount() {
      closeRef.call(this, 'host-edit-bank-account-slider');
    },

    onClickRequestSquareUp() {
      // if (!this.storeId) {
      //   alert('정산 받으실 상점을 먼저 선택해주세요.');
      //   return;
      // }

      this.$store.commit('loading/SET_TRUE');
      // setTimeout(() => {
      //   this.$store.commit('loading/SET_FALSE');
      //   this.$router.push({
      //     name: 'HostSettlementRequest',
      //     query: { store_id: this.storeId }
      //   });
      // }, 500);
      setTimeout(() => {
        this.$store.commit('loading/SET_FALSE');
        this.$router.push({
          name: 'HostSettlementAmount',
          // query: { store_id: this.storeId }
          params: { store_id: this.storeId }
        });
      }, 500);
    }
  },

  components: {
    HostBankAccountList,
    UiSlider
  }
};
</script>

<style lang="scss" scoped>
.host-settlement-dashboard {
  .dashboard {
    &__section {
      margin: unit(12) 0;
    }
  }

  .settlement-info {
    // display: flex;
    width: 100%;
    // flex-wrap: wrap;;
    // gap: unit(16);
    margin-top: unit(50);
    font-weight: 700;
    font-size: unit(22);
    text-align: center;

    &__list {
      width: 100%;
    }

    &__list-item {
      flex: 1;
      display: flex;
      gap: unit(16);
      justify-content: space-between;

      & + .settlement-info__list-item {
        margin-top: unit(4);
      }

      .key {
        flex: 1;
        text-align: left;
        font-size: unit(14);
        color: #61676c;

        &--primary {
          font-size: unit(20);
          color: $color-black;
          font-weight: bold;
        }
      }

      .value {
        flex: 1;
        text-align: right;
        font-size: unit(14);
        font-weight: 500;
        color: #61676c;

        &--primary {
          font-size: unit(20);
          color: $color-primary;
          font-weight: bold;
        }
      }
    }
  }

  .actions {
    padding: unit(12) 0;
    margin-top: unit(48);
    margin-bottom: 0;
    min-height: unit(44);
    border-top: 1px solid $color-gray-3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 0;
    gap: unit(12);

    .text {
      font-size: unit(12);
      color: #61676c;
      line-height: unit(24);

      &--underline {
        font-size: unit(14);
        color: #61676c;
        font-weight: bold;
        line-height: unit(24);
        text-decoration: underline;
      }
    }

    .account-area {
      margin-right: unit(12);
      line-height: unit(24) !important;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: unit(4);

      i {
        align-self: flex-start;
        font-size: unit(16);
        padding: unit(4) 0;
      }

      .account-info {
        display: inline-block;
        gap: unit(4);
        cursor: pointer;
        align-items: flex-start;
      }
    }

    .button-area {
      display: flex;
      gap: unit(12);
      margin: auto;
      justify-content: center;

      .btn {
        width: auto;
        color: #61676c;
        font-weight: 500;
        border-radius: unit(6);
        font-size: unit(14);
        line-height: 1.35;
        padding: unit(8) unit(16);
        cursor: pointer;

        &.activated {
          background: $color-primary !important;
          color: $color-white !important;

          &::hover {
            background: $color-primary-4 !important;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }

        &--text {
          text-decoration: underline;
          padding: unit(8) unit(4);
          font-weight: bold;
          font-size: unit(14);
        }

        &--fill {
          background: $color-gray-2;

          &::hover {
            background: $color-gray-6;
          }
        }
      }
    }
  }

  .tax-info {
    font-size: unit(11);
    width: 98%;
    background: $color-gray-1;
    border-radius: unit(20);
    margin: 0 auto unit(20) auto;
    padding: unit(10);

    .tax-info_title {
      font-weight: 700;
    }
    .tax-info_content {
      padding-left: unit(5);
    }
  }
}
</style>
