<template>
  <div class="host-edit-bank-account">
    <h1 class="host-edit-bank-account__header">
      <span class="header__text"> 계좌 정보 </span>
    </h1>
    <div class="host-edit-bank-account__body">
      <div class="section select-account">
        <h2 v-if="selectedAccount.bank_account" class="section__header">정산받으실 계좌</h2>
        <div class="section__body">
          <div class="primary-account-info">
            <strong class="primary-account-info__bank-name">{{ selectedAccount.bank_name }}</strong>
            <p class="primary-account-info__bank-account">
              {{ selectedAccount.bank_owner_name }} {{ selectedAccount.bank_account }}
            </p>
          </div>
          <ul class="account-list" ref="list">
            <li
              v-for="item in accountList"
              :key="item.uid"
              class="account-list__item"
              :class="{ activated: item === selectedAccount }"
              @click="onClickAccountItem(item)"
            >
              {{ `${item.bank_name} ${item.bank_account}` }}
              <button type="button" @click.stop="onClickDeleteAccount(item)" class="btn btn--remove">
                <img src="@/assets/icons/svg/close.svg" />
              </button>
            </li>
            <li class="account-list__item account-list__item--empty">
              <button type="button" @click="onClickAddAccount" class="btn btn--add">
                <i class="fas fa-plus"></i>&nbsp;
                <span class="btn__text">계좌 추가하기</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="host-edit-bank-account__footer">
      <button type="button" class="btn btn--submit" @click="onClickConfirm">확인</button>
    </div>

    <ui-slider id="register-bank-account-slider" ref="register-bank-account-slider">
      <HostRegisterBankAccount @close="onCloseRegister" @submit="onSubmitRegister" />
    </ui-slider>
  </div>
</template>

<script>
import { openRef, closeRef } from '@/helpers';
import UiSlider from '@/components/modules/Slider/Body.vue';
import HostRegisterBankAccount from '@/components/resources/host/HostRegisterBankAccount';

export default {
  data() {
    return {
      selectedAccount: {},
      componentKey: 0,
    };
  },

  computed: {
    primaryAccount() {
      return this.accountList.find((o = {}) => !!o.is_primary);
    },

    accountList() {
      return this.$store.getters['bankAccounts/GET_ITEMS_BY_LIST']({ list: 'MyBankAccounts' }).sort();
    },
  },

  methods: {
    async fetchBankAccount() {
      try {
        this.$store.commit('loading/SET_TRUE');
        await this.$store.dispatch('bankAccounts/fetchList', {
          page: 1,
          size: 100,
          list: 'MyBankAccounts',
        });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    async makePrimaryAccount(account) {
      const { uid, bank_owner_name, bank_country, bank_name, bank_code, bank_account } = account || {};
      if (!uid) return;

      try {
        this.$store.commit('loading/SET_TRUE');
        await this.$store.dispatch('bankAccounts/updateOne', {
          id: uid,
          bank_owner_name,
          bank_country,
          bank_name,
          bank_code,
          bank_account,
          is_primary: 1,
        });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        this.$store.commit('loading/SET_FALSE');
      }
    },

    onClickAccountItem(item) {
      this.selectedAccount = item;
      setTimeout(this.scrollToSelectedAccount, 0);
    },

    onClickAddAccount() {
      openRef.call(this, 'register-bank-account-slider');
    },

    onCloseRegister() {
      closeRef.call(this, 'register-bank-account-slider');
    },

    onSubmitRegister(item) {
      closeRef.call(this, 'register-bank-account-slider');
      this.selectedAccount = item;
    },

    onClickConfirm() {
      this.makePrimaryAccount(this.selectedAccount).then((_) => {
        this.fetchBankAccount().then((_) => {
          this.$emit('close');
        });
      });
    },

    onClickDeleteAccount({ uid }) {
      if (uid === this.selectedAccount.uid) {
        this.selectedAccount = {};
      }

      this.$store.dispatch('bankAccounts/deleteOne', { id: uid }).then((_) => {
        this.fetchBankAccount();
      });
    },

    scrollToSelectedAccount() {
      setTimeout(() => {
        this.$refs.list.scrollTo(0, 0);
        const offset = 40;
        const list = this.$refs.list;
        const targetItem = this.$refs.list.querySelector('.activated');
        if (!targetItem) return;

        const targetItemAbsLeft = window.scrollX + targetItem.getBoundingClientRect().left;
        const listAbsLeft = window.scrollX + list.getBoundingClientRect().left;

        this.$refs.list.scrollTo(targetItemAbsLeft - listAbsLeft - offset, 0);
      }, 0);
    },
  },

  async created() {
    if (this.accountList.length < 1) {
      await this.fetchBankAccount();
    }

    this.selectedAccount = this.primaryAccount || {};
    this.scrollToSelectedAccount();
  },

  components: {
    UiSlider,
    HostRegisterBankAccount,
  },
};
</script>

<style lang="scss" scoped>
.host-edit-bank-account {
  &__header {
    .header__text {
      font-size: unit(22);
      font-weight: bold;
    }
  }

  &__body {
    padding: unit(16) 0;

    .section {
      &__header {
        font-size: unit(14);
        color: #61676c;
        font-weight: lighter;
        line-height: 1.5;
      }
    }

    .primary-account-info {
      &__bank-name {
        font-size: unit(28);
        font-weight: 500;
        color: #61676c;
        line-height: 1.5;
      }

      &__bank-account {
        font-size: unit(24);
        font-weight: 500;
        color: $color-black;
        text-align: right;
        line-height: 1.5;
      }
    }

    .account-list {
      width: 100%;
      overflow: auto;
      overscroll-behavior: contain;
      white-space: nowrap;
      padding: unit(24) unit(8) unit(12) unit(8);

      &__item {
        display: inline-block;
        width: auto;
        border: solid 1px #e1e4e6;
        border-radius: unit(12);
        color: #61676c;
        padding: unit(2) unit(24) unit(2) unit(8);
        cursor: pointer;
        max-width: 300px;
        @include ellipsis(1);

        & + * {
          margin-left: unit(6);
        }

        &.activated {
          background: $color-primary;
          color: $color-white;
          font-weight: 500;
        }

        &--empty {
          cursor: pointer;
        }
      }
    }
  }

  &__footer {
    position: sticky;
    bottom: 0;
    padding: unit(16) 0;
  }

  .btn {
    padding: unit(8);
    cursor: pointer;

    &--submit {
      background: $color-primary;
      font-size: unit(16);
      font-weight: 500;
      color: $color-white;
      border-radius: unit(6);
    }

    &--add {
      font-size: unit(12);
      color: #61676c;
      padding: 0;
    }

    &--remove {
      padding: 0;
      width: unit(14);
      height: unit(14);
      display: inline-block;
      vertical-align: text-bottom;
      margin-left: unit(4);
      border-radius: 50%;
      border: solid 1px $color-black;
      background: $color-white;
      position: absolute;
      right: unit(4);
      top: 50%;
      transform: translateY(-50%);

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
