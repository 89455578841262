<template>
  <div class="host-settlement-list">
    <div class="list-header">
      <h2 class="list-label">정산 내역</h2>
    </div>
    <div class="list-body">
      <div class="container">
        <div class="list-table list-table--primary">
          <div class="list-table__caption">금액 단위: 원(KRW)</div>
          <div class="list-table__header">
            <!-- <div class="list-table__cell">No.</div> -->
            <div class="list-table__cell">상점명</div>
            <div class="list-table__cell">정산상태</div>
            <!-- <div class="list-table__cell">정산 생성일</div> -->
            <div class="list-table__cell">정산 기간</div>
            <div class="list-table__cell">계좌 정보</div>

            <!-- <div class="list-table__cell">상점 매출액 (A)</div> -->
            <!-- <div class="list-table__cell">정산 수수료 (B)</div> -->
            <!-- <div class="list-table__cell">원천세 (C)</div> -->
            <div class="list-table__cell">정산 금액</div>
            <div class="list-table__cell">정산 방식</div>
            <div class="list-table__cell">내역</div>
          </div>
          <div class="list-table__body">
            <!-- <div class="list-table__row" v-for="(item, index) in docs" :key="index"> -->
            <div class="list-table__row" v-for="(item, index) in settledList()" :key="index">
              <!-- <div class="list-table__cell">
                {{ index + 1 }}
              </div> -->
              <div class="list-table__cell">{{ item.store_name_localed }}</div>
              <div class="list-table__cell">{{ getStatusText(item) }}</div>
              <!-- <div class="list-table__cell">
                {{ item.created_at | moment("YYYY-MM-DD HH:mm") }}
              </div> -->
              <div class="list-table__cell">{{ item.settled_date_start }} ~ {{ item.settled_date_end }}</div>
              <div class="list-table__cell">{{ item.settled_bank_name }}<br />{{ item.settled_bank_account }}</div>

              <!-- <div class="list-table__cell">{{ item.total_origin }}</div> -->
              <!-- <div class="list-table__cell">{{ item.total_fee }}</div> -->
              <!-- <div class="list-table__cell">{{ item.total_tax }}</div> -->
              <div class="list-table__cell list-table__cell--primary">
                {{ item.settled_total }}
              </div>

              <!--정산방식-->
              <div class="list-table__cell">{{ getMethodText(item) }}</div>
              <!--내역-->
              <div class="list-table__cell">
                <button type="button" @click="onClickShowDetail(item)">{{ item.items.length - cancel(item.items) }} 건</button>
              </div>
            </div>
          </div>
        </div>

        <div class="list-pager" v-if="loadable">
          <button type="button">{{ $__t('더 불러오기') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const SETTLEMENT_STATUS = {
  NEW: '정산가능',
  PROGRESS: '정산진행중',
  SETTLED: '정산완료'
};

const SETTLED_METHODS = {
  WITHHOLDING_TAX: '원천징수',
  TAX_BILL: '세금 계산서'
};

export default {
  props: {
    storeId: {
      type: Number | String
    }
  },

  data() {
    return {
      listQuery: {
        page: 1,
        size: 20,
        total: 0
      },

      loading: false,
      error: ''
    };
  },

  watch: {
    storeId: {
      async handler() {
        // await this.fetchList();
      }
      // immediate: true
    },

    loading(value) {
      if (value) {
        this.$store.commit('loading/SET_TRUE');
      } else {
        this.$store.commit('loading/SET_FALSE');
      }
    }
  },

  computed: {
    //@MEMO: 과거 정산내역 store에서 가지고 온다.
    docs() {
      // console.log(
      //   'docs',
      //   this.$store.getters['hostSettlements/GET_ITEMS_BY_LIST']({
      //     list: this.listName
      //   })
      // );
      return this.$store.getters['hostSettlements/GET_ITEMS_BY_LIST']({
        list: this.listName
      });
    },

    listName() {
      return this.storeId ? `MyStoreSettlements_${this.storeId}` : 'MyStoreSettlements_all';
    },

    storeListSelectOptions() {
      return this.storeList.map((o) => {
        return {
          label: o.store_name,
          value: o.id
        };
      });
    },

    loadable() {
      return this.listQuery.page * this.listQuery.size < this.listQuery.total;
    }
  },

  methods: {
    // async fetchList() {
    //   try {
    //     this.loading = true;
    //     const { total } = await this.$store.dispatch(`hostSettlements/${this.storeId ? 'fetchListByStore' : 'fetchList'}`, {
    //       ...this.listQuery,
    //       list: this.listName,
    //       id: this.storeId
    //     });

    //     this.listQuery.total = total;
    //   } catch (e) {
    //     this.error = e;
    //   } finally {
    //     this.loading = false;
    //   }
    // },

    //@MEMO:정산내역 중, 정산가능, zero_settled 리스트는 제외한 나머지 리스트.
    settledList() {
      let lists = [];
      for (let i = 0; i < this.docs.length; i++) {
        // if (this.docs[i].status !== 'NEW' && this.docs[i].status !== 'ZERO_SETTLED') {
        //   lists.push(this.docs[i]);
        // }
        const doc = this.docs[i];
        if (!['NEW', 'ZERO_SETTLED'].includes(doc.status)) {
          lists.push(doc);
        }
      }
      return lists;
    },

    getStatusText({ status }) {
      return SETTLEMENT_STATUS[status];
    },

    getMethodText({ settled_method }) {
      return SETTLED_METHODS[settled_method];
    },

    onClickShowDetail({ uid }) {
      this.$router.push({ name: 'HostSettlementDetail', params: { id: uid } });
    },
    cancel(items) {
      let cancelCount = 0;
      for (let i = 0; i < items.length; i++) {
        if (items[i].status === 'CANCELLED') {
          cancelCount++;
        }
      }
      return cancelCount;
    }
  }
};
</script>

<style lang="scss" scoped>
.host-settlement-list {
  .list-header {
    display: flex;
    justify-content: space-between;
    gap: unit(12);

    .list-label {
      font-size: unit(18);
      font-weight: 500;
    }

    .list-select {
      .select::v-deep {
        width: unit(160);
      }

      .select--round::v-deep {
        .body {
          border-radius: unit(16);
        }
      }
    }
  }

  .list-body {
    margin-top: unit(16);
    padding: unit(16) 0;
    min-height: 480px;

    .container {
      overflow: auto;
      overscroll-behavior: contain;
      padding-bottom: unit(24);
    }
  }

  .list-table {
    display: table;
    border-collapse: collapse;
    width: 100%;

    &__header {
      display: table-header-group;
      text-align: center;

      .list-table__cell {
        background: #f1f5f5;
        font-weight: 500;
        margin: 0 auto;
      }
    }

    &__row {
      display: table-row;
    }

    &__body {
      display: table-row-group;
    }

    &__cell {
      display: table-cell;
      padding: unit(8);
      text-align: center;
      line-height: 1.5;
      vertical-align: middle;
      min-width: 120px;
      border: solid 1px #e1e4e6;

      button {
        word-wrap: break-word;
        word-break: break-all;
        width: auto;
        border: solid 1px #e1e4e6;
        line-height: 1.35;
        padding: unit(4);
        font-size: unit(10);
        color: #61676c;
        margin: auto;
        border-radius: $radius;
        cursor: pointer;
        @include shadow-box;

        &:hover {
          background: rgba(0, 0, 0, 0.125);
        }
      }

      &--nested {
        padding: 0;
        border: 0;
        display: table;
        table-layout: fixed;
        width: 100%;

        .list-table__header {
          border-top: solid 1px #e1e4e6;
          border-right: solid 1px #e1e4e6;
          border-bottom: solid 1px #e1e4e6;

          & > .list-table__cell {
            border: 0;
          }
        }

        .list-table__body {
          border-bottom: solid 1px #e1e4e6;

          & > .list-table__cell {
            border: 0;
            border-right: solid 1px #e1e4e6;
          }
        }

        .list-table__cell {
          width: 100px;
        }
      }

      &--primary {
        color: $color-primary;
      }
    }

    &__caption {
      display: table-caption;
      width: 100%;
      text-align: right;
      font-weight: 500;
    }
  }
  .list-pager {
    margin-top: unit(24);

    button {
      width: auto;
      margin: auto;
      padding: unit(4) unit(8);
      font-size: unit(14);
      font-weight: 500;
      border-radius: unit(30);
      border: solid 1px $color-primary;
      color: $color-primary;
    }
  }
}
</style>
