<template>
  <div class="page host-settlements">
    <div class="page-header">
      <!-- <h1 class="page-header__title">
        <div class="list-select">
          <lug-select v-model="selectedStoreId" :items="storeListSelectOptions" :placeholder="$__t('전체')" class="select select--round" />
        </div>
        상점의 정산정보
      </h1> -->
      <h1 class="page-header__title">{{ storeList[0].host_name }} 님의 정산정보</h1>
    </div>

    <div class="page-content">
      <!-- <section class="page-content__section"></section> -->

      <div class="page-content__section">
        <HostSettlementDashboard :store-id="selectedStoreId" />
      </div>

      <div class="page-content__section">
        <HostSettlementList :store-id="selectedStoreId" />
      </div>
    </div>
  </div>
</template>

<script>
import HostSettlementList from '@/components/resources/host/HostSettlementList.vue';
import HostSettlementDashboard from '@/components/resources/host/HostSettlementDashboard.vue';

export default {
  data() {
    return {
      listQuery: {
        page: 1,
        size: 50
      },
      listQueryForSettlement: {
        page: 1,
        size: 20,
        total: 0
      },
      selectedStoreId: ''
    };
  },

  computed: {
    storeList() {
      return this.$store.getters['stores/GET_ITEMS_BY_LIST']({ list: 'MyStores' });
    },

    storeListSelectOptions() {
      return this.storeList.map((o) => {
        return {
          label: o.store_name,
          value: o.id
        };
      });
    }
  },

  created() {
    //@MEMO:항상 전체를 받아오기때문에 fetchStores를 할 필요가 없다.
    // this.fetchStores().then((_) => {
    //   if (Array.isArray(this.storeList) && this.storeList.length === 1) {
    //     this.selectedStoreId = this.storeList[0].id;
    //   }
    // });
  },

  mounted() {
    this.fetchList();
  },

  methods: {
    //@MEMO: 여기서 먼저 fetchList를 한다. 그런다음 dashboard, list에서 getters로 가져온다.
    async fetchList() {
      try {
        this.loading = true;
        const { total } = await this.$store.dispatch(`hostSettlements/fetchList`, {
          ...this.listQuery,
          // list: this.listName,
          list: 'MyStoreSettlements_all',
          id: this.storeId
        });
        this.listQuery.total = total;
      } catch (e) {
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
    //here
    async fetchStores() {
      try {
        this.loading = true;
        await this.$store.dispatch('stores/getListForHost', { list: 'MyStores', query: this.listQuery });
      } catch (e) {
        this.$store.commit('alert/ADD_ITEM', { message: e, status: 'error' });
      } finally {
        this.loading = false;
      }
    }
  },

  components: {
    HostSettlementList,
    HostSettlementDashboard
  }
};
</script>

<style lang="scss" scoped>
.page.host-settlements {
  .page-header {
    position: sticky;
    top: unit(56);
    background: $color-white;
    z-index: 1;

    &::after {
      content: '';
      @include fsa;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.5);
      transition: opacity 0.2s ease-out;
    }

    .list-select {
      display: inline-block;
      vertical-align: middle;
      margin-right: unit(12);
      width: unit(160);
      z-index: 1;
    }
  }

  .page-content {
    &__section {
      padding: unit(16);

      & + .page-content__section {
        padding-top: unit(48);
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 24px;
        background: #f1f5f5;
      }
    }
  }
}
</style>
